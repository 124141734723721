import React from 'react';
import { graphql } from 'gatsby';
import { Box, Container, Text, VStack } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { EventLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import Html from 'timelesstime-ui/components/html';
import Heading from 'timelesstime-ui/components/heading';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';
import EventCard from '../components/event-card';

const EventsPage = ({ data: { eventsPage, eventNodes } }) => {
  const events = eventNodes.nodes;
  return (
    <PageLayout
      leadInHeader={
        <EventLeadInHeader
          heading={eventsPage.heading || eventsPage.title}
          crumbs={[
            {
              url: '/events/',
              title: eventsPage.title,
            },
          ]}
        />
      }
    >
      <SEO
        title={eventsPage.title}
        keywords={eventsPage.fields.keywords}
        description={eventsPage.metaDescription}
        canonicalPath="/events/"
        thumbnail={eventsPage.jsonLdThumbnailImage}
      />

      <Container as="section" mb={4} maxW="container.lg">
        <Html source={eventsPage.fields.contentHtml} headerLevelStart={1} />
      </Container>

      <Container maxW="container.lg" mt={4} mb={4}>
        <VStack spacing={12}>
          {events.length > 0 &&
            events.map((event, index) => (
              <Box as="section" key={event.id}>
                <EventCard event={event} imageOnRight={index % 2 === 0} />
              </Box>
            ))}
        </VStack>
        {events.length < 1 && (
          <>
            <Heading as="h1">
              It looks like we don&apos;t have any upcoming events at the moment
            </Heading>
            <Text>
              We&apos;ll be adding some more soon, so check back later.
            </Text>
            <Text>
              In the meantime, why not have a look at our{' '}
              <CanonicalLink color="orange.500" to="/knowledgebase/">
                knowledgebase
              </CanonicalLink>{' '}
              or the{' '}
              <CanonicalLink color="orange.500" to="/services/">
                services we offer
              </CanonicalLink>
              ?
            </Text>
          </>
        )}
      </Container>

      <Jumbotron as="aside" py={8} bg="tt.darkBlue">
        <NewsletterSignup />
      </Jumbotron>
    </PageLayout>
  );
};

export const query = graphql`
  query EventsPageQuery($dateToday: Date!) {
    eventsPage: contentfulPage(slug: { eq: "events" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
    eventNodes: allContentfulEventInstance(
      sort: { fields: startDate, order: ASC }
      filter: { title: { ne: null }, startDate: { gte: $dateToday } }
    ) {
      nodes {
        id
        title
        slug
        type
        metaDescription
        title
        locationName
        address
        ticketUrl
        informationUrl
        isSoldOut
        maxTicketPrice
        minTicketPrice
        ticketUrl
        isOnline
        latLng {
          lat
          lon
        }
        startDate
        endDate
        isPartOfSeries
        positionInSeries
        numberOfEventsInSeries
        createdAt
        featuredImage {
          ...FeaturedImage
        }
        jsonLdFeaturedImage: featuredImage {
          ...JsonLdFeaturedImage
        }
        jsonLdThumbnailImage: featuredImage {
          ...JsonLdThumbnailImage
        }
        speakers {
          id
          firstName
          lastName
          email
          jobTitle
        }
        eventbriteEventId
        fields {
          path
          url
          writtenAt
          isNew
          keywords
          excerptHtml
        }
      }
    }
  }
`;

export default EventsPage;
