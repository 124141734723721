import React, { Fragment } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import format from 'date-fns/format';
import { Divider, Box, HStack, Text } from '@chakra-ui/react';

import NewBadge from 'timelesstime-ui/components/new-badge';
import GoogleMap from 'timelesstime-ui/components/google-map';
import Html from 'timelesstime-ui/components/html';
import ContentCard from 'timelesstime-ui/components/content-card';
import ButtonLink from 'timelesstime-ui/components/button-link';
import TypeBadge from '../type-badge';
import EventStructuredData from '../structured-data/event';

const featuredImageHtml = (event) => {
  if (event.isOnline) {
    return (
      <GatsbyImage
        image={getImage(event.featuredImage)}
        alt={event.featuredImage.title}
      />
    );
  }
  if (event.latLng && event.latLng.lat && event.latLng.lon) {
    return (
      <Box
        height="100%"
        width="100%"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
      >
        <GoogleMap
          center={{
            lat: event.latLng.lat,
            lng: event.latLng.lon,
          }}
          markerLocation={{
            lat: event.latLng.lat,
            lng: event.latLng.lon,
          }}
          zoom={12}
          draggable={false}
          scrollwheel={false}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
        />
      </Box>
    );
  }
  return (
    <GatsbyImage
      image={getImage(event.featuredImage)}
      alt={event.featuredImage.title}
    />
  );
};

const EventCard = ({ event, ...props }) => (
  <ContentCard
    url={event.fields.path}
    title={
      <Box>
        <ContentCard.Heading to={event.fields.path} mb={2}>
          {event.title}
        </ContentCard.Heading>
        <Text as="strong">
          {format(new Date(event.startDate), 'do MMMM yyyy')}
        </Text>
        <HStack spacing={1} mt={2}>
          <TypeBadge type={event.type} title={event.type} />
          {event.isOnline && (
            <TypeBadge dark type="Online" title="Online only event" />
          )}
          <NewBadge isNew={event.fields.isNew} />
          <Box pl={2}>
            {(event.speakers && event.speakers.length) > 0 && (
              <>
                Presented by
                {event.speakers.map((speaker, index) => (
                  <Fragment key={speaker.id}>
                    {index > 0 && '&'} {speaker.firstName} {speaker.lastName}{' '}
                  </Fragment>
                ))}
              </>
            )}
          </Box>
        </HStack>
        <Divider mt={2} mb={4} />
      </Box>
    }
    image={featuredImageHtml(event)}
    structuredData={
      <EventStructuredData
        slug={event.slug}
        title={event.title}
        speakers={event.speakers}
        description={event.metaDescription}
        startDate={new Date(event.startDate)}
        endDate={new Date(event.endDate)}
        locationName={event.locationName}
        address={event.address}
        latLng={event.latLng}
        isFree={
          (event.minTicketPrice === undefined || event.minTicketPrice === 0) &&
          (event.maxTicketPrice === undefined || event.maxTicketPrice === 0)
        }
        isOnline={event.isOnline}
        ticketUrl={event.ticketUrl}
        createdAt={new Date(event.createdAt)}
        minTicketPrice={event.minTicketPrice}
        maxTicketPrice={event.maxTicketPrice}
        isSoldOut={false}
        thumbnailImage={event.jsonLdThumbnailImage}
        featuredImage={event.jsonLdFeaturedImage}
        shortMeta
      />
    }
    excerpt=""
    moreText={
      <ButtonLink
        size="sm"
        variant="solid"
        colorScheme="orange"
        width="auto"
        to={event.fields.path}
        mt={4}
      >
        find out more & get a ticket
      </ButtonLink>
    }
    {...props}
  >
    <Html source={event.fields.excerptHtml} headerLevelStart={2} />
  </ContentCard>
);

export default EventCard;
